import React from "react";
import PropTypes from "prop-types";
import { Link, Router, Route } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import { withTranslation } from "react-i18next";

import styles from "./Editor.module.scss";
import history from "../../history";
import httpClient from "../../lib/HttpClient";

import security from "../../services/Security";

/* UI Kit */
import { UikButton } from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Papercurve Components */
import Header from "../shared/Header/Header";

/* Assets */
import plusIcon from "../../images/icons/svg/plus-grey.svg";

/* Variables */
class EditorBuild extends React.Component {
  constructor() {
    super();

    this.docEditor = React.createRef();
  }

  componentWillMount = () => {};

  componentDidMount = () => {
    const docId = this.props.match.params.id;

    if (document.getElementById("root")) {
      document.getElementById("root").className = styles.fullHeight;
    }

    if (document.getElementById("app_container")) {
      document.getElementById("app_container").className = styles.fullHeight;
    }

    let url = `/documents/${docId}.json`;

    httpClient.get(url).then((response) => {
      let documentProperties = {};
      let fileType = "docx";
      let documentType = "word";
      let userId = security.getUserId();
      let userName = `${security.getUserFirstName()} ${security.getUserLastName()}`;

      documentProperties = response.data;

      if (
        documentProperties.mime_type ==
          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        documentProperties.mime_type ==
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        fileType = "pptx";
        documentType = "slide";
      } else if (
        documentProperties.mime_type == "application/vnd.ms-powerpoint"
      ) {
        fileType = "ppt";
        documentType = "slide";
      } else if (
        documentProperties.mime_type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        documentProperties.mime_type == "text/csv"
      ) {
        fileType = "xlsx";
        documentType = "cell";
      } else if (documentProperties.mime_type == "application/vnd.ms-excel") {
        fileType = "xls";
        documentType = "cell";
      }

      // Load the document / only office editor.
      let config = {
        document: {
          permissions: {
            comment: false,
            copy: true,
            download: false,
            protect: false,
            review: false,
            edit: true,
          },
          fileType: fileType,
          key: `${process.env.REACT_APP_WORKSPACE}-${docId}-${documentProperties["version_number"]}`,
          title: documentProperties["title"],
          url: `${process.env.REACT_APP_API_URL}/documents/${docId}/file${documentProperties["file_extension"]}`,
        },
        documentType: documentType,
        editorConfig: {
          coEditing: {
            mode: "fast",
            change: false,
          },
          customization: {
            forcesave: true,
            comments: false,
            compactHeader: false,
            compactToolbar: false,
            help: false,
            hideRightMenu: true,
            hideRulers: true,
            macros: false,
            uiTheme: "theme-classic-light",
            hideNotes: true,
            hideRightMenu: true,
            integrationMode: "embed",
            logo: {
              image: "https://edit.papercurve.com/welcome/nologo.png",
              imageDark: "https://edit.papercurve.com/welcome/nologo.png",
              url: "",
            },
            plugins: false,
          },
          callbackUrl: `${process.env.REACT_APP_API_URL}/documents/${docId}/edit.json`,
          user: {
            id: `${userId}`,
            name: userName,
            group: `${process.env.REACT_APP_WORKSPACE}`,
          },
        },
      };
      console.log("config", config);
      window.onlyOfficeDocEditor = new window.DocsAPI.DocEditor(
        "placeholder",
        config
      );
    });
  };

  closeEditor = () => {
    history.push(`/drafts/${this.props.match.params.id}?latest=true`);
  };

  render() {
    return (
      <Container fluid className={styles.fullHeight}>
        <Header />
        <Row className={styles.onlyOfficeHeader}>
          <span className={styles.onlyOfficeInstructions}>
            To commit your changes into a new version, click: File > Save. It
            will publish a new version then you can close the editor.
          </span>
          <UikButton
            onClick={this.closeEditor}
            className={styles.closeEditorButton}
          >
            Close Editor
          </UikButton>
        </Row>
        <Row className={styles.editorContainer}>
          <div id="placeholder" className={styles.fullHeight}></div>
        </Row>
      </Container>
    );
  }
}

const Editor = withTranslation()(EditorBuild);

export default Editor;
